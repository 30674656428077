
$(function(){
  // if (window.matchMedia('(max-width: 540px)').matches) {
  //   $(".p-jacketInner").on('scroll', function (){
  //     var elem = $('.elem');
  //     var appear = 'appear';
  //     elem.each(function () {
  //       var elemScrollLeft = $(this).offset().left;
  //       var scroll = $(".p-jacketInner").scrollLeft();
  //       var ww = $(".p-jacketInner").width();
  //       if(ww + scroll > elemScrollLeft + (ww / 10) ){
  //         $(this).removeClass(appear);
  //         }
  //         $('.cla01').css('opacity',scroll);
  //         $('.cla02').css('opacity',elemScrollLeft);
  //         $('.cla03').css('opacity',ww);
  //     });
  //   });
  // } else {
    $(window).on('scroll', function (){

      var elem = $('.elem');
      var appear = 'appear';

      elem.each(function () {

        var elemOffset = $(this).offset().top;
        var scrollPos = $(window).scrollTop();
        var wh = $(window).height();

        if(scrollPos > elemOffset - wh + (wh / 5) ){
          $(this).removeClass(appear);
        }
      });
    });
	// }
})
